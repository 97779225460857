<template>
  <div class="container">
    <div class="main-content">
      <div class="order-details">
        <h1>Detalhes da Encomenda</h1>

        <div class="order-info">
          <p><strong>ID Encomenda:</strong> {{ order.id }}</p>
          <p>
            <strong>Nome:</strong>
            {{ order.first_name + ' ' + order.second_name }}
          </p>
          <p>
            <strong>Morada:</strong> {{ order.city + ', ' + order.adress1 }}
          </p>
          <p><strong>Total:</strong> {{ order.total + ' ' + order.coin }}</p>
        </div>

        <h2 style="padding: 5px 10px">
          Artigos
          <button
            style="font-size: 18px; padding: 5px 10px"
            @click="showModal = true"
          >
            &#43;
          </button>
          <!-- Modal -->
          <b-modal v-model="showModal" title="Adicionar Artigo" hide-footer>
            <b-form @submit.prevent="submitForm">
              <b-form-group label="SKU:" label-for="product-sku">
                <b-form-input
                  id="product-sku"
                  v-model="sku"
                  required
                  placeholder="Introduza o SKU"
                >
                </b-form-input>
              </b-form-group>

              <b-form-group label="Quantidade:" label-for="product-quantity">
                <b-form-input
                  id="product-quantity"
                  v-model="prod_quantity"
                  required
                  placeholder="Introduza a Quantidade"
                >
                </b-form-input>
              </b-form-group>

              <b-form-group label-for="product-price">
                <template #label> Preço ({{ order.coin }}) </template>
                <b-form-input
                  id="product-price"
                  v-model="prod_price"
                  required
                  placeholder="Introduza o Preço"
                >
                </b-form-input>
              </b-form-group>

              <b-button type="submit" variant="success">Adicionar</b-button>
            </b-form>
          </b-modal>
        </h2>

        <ul class="article-list">
          <li
            v-for="article in order.articles"
            :key="article.id"
            :class="{ selected: selectedArticle?.id === article.id }"
            @click="selectArticle(article)"
          >
            <strong>{{ article.quantity }}x [{{ article.sku }}]</strong>
            {{ article.name }}
          </li>
        </ul>
      </div>
    </div>

    <div class="sidebar" v-if="selectedArticle">
      <h3>Editar Artigo</h3>
      <p><strong>Artigo:</strong> {{ selectedArticle.name }}</p>
      <label>Quantidade:</label>
      <input type="number" v-model.number="editedQuantity" min="1" />
      <button @click="updateQuantity" style="margin-right: 10px">
        Atualizar
      </button>
      <button @click="removeArticle(selectedArticle)">Remover Artigo</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex/dist/vuex.common.js';

export default {
  data() {
    return {
      order: {},
      selectedArticle: null,
      editedQuantity: 1, // Novo estado para a quantidade editada
      showModal: false,
      sku: '',
      prod_quantity: 1,
      prod_price: '',
    };
  },
  methods: {
    async loadData() {
      await this.fetchOrder(this.$route.params.orderId);
    },
    async removeArticle(article) {
      this.order.articles = this.order.articles.filter(
        (item) => item.id !== article.id
      );
      await this.calcTotal();
      await this.modOrder();
    },
    async submitForm() {
      console.log('Product SKU:', this.sku);
      this.showModal = false; // Close modal after submission
      try {
        await this.$store.dispatch('searchProductsEanSku', {
          search: this.sku,
        });
        const article_search = this.getProductsSkuEan[0];
        console.log('Article:', article_search);
        let tax_class = '';
        if (article_search.tax_iva === 0) {
          tax_class = 'zero-rate';
        } else if (article_search.tax_iva === 6) {
          tax_class = 'reduced-rate';
        } else if (article_search.tax_iva === 23) {
          tax_class = '';
        }

        const article = {
          all_ean: article_search.ean,
          all_sku: article_search.sku,
          country: article_search.country,
          ean: article_search.ean[0],
          hscode: article_search.hscode,
          id: article_search.id_backoffice,
          image: article_search.img,
          meta_data: [],
          name: article_search.name,
          note_finish: article_search.note_finish,
          note_warehouse: article_search.note_warehouse,
          parent_name: '',
          price: this.prod_price,
          product_id: article_search.id_backoffice,
          quantity: this.prod_quantity,
          sku: article_search.sku[0],
          stock: '',
          subtotal: parseFloat(this.prod_price * this.prod_quantity),
          subtotal_tax: '',
          tax_class: tax_class,
          taxes: '',
          total: parseFloat(this.prod_price * this.prod_quantity),
          total_tax: '',
          validity: article_search.validity,
          variation_id: 0,
          weight: article_search.weight,
        };
        this.order.articles.push(article);
        await this.calcTotal();
        await this.modOrder();
        //await this.fetchOrder(this.$route.params.orderId);
      } catch (error) {
        console.log(error);
      }
    },
    selectArticle(article) {
      this.selectedArticle = article;
      this.editedQuantity = article.quantity; // Atualiza a quantidade ao selecionar
    },
    async updateQuantity() {
      if (this.selectedArticle) {
        this.selectedArticle.quantity = this.editedQuantity;
      }

      for (let i = 0; i < this.order.articles.length; i++) {
        if (this.order.articles[i].id === this.selectedArticle.id) {
          this.order.articles[i].quantity = this.editedQuantity;
          this.order.articles[i].total = (
            this.order.articles[i].price * this.editedQuantity
          ).toFixed(2);
          this.order.articles[i].subtotal = (
            this.order.articles[i].price * this.editedQuantity
          ).toFixed(2);
        }
      }
      await this.calcTotal();
      await this.modOrder();
    },
    async modOrder() {
      console.log('Modify Order');
      try {
        const payload = {
          order_id: this.order.id,
          articles: this.order.articles,
          total: this.order.total,
        };
        //console.log(payload);
        this.$store.dispatch('modifyOrder', payload);
      } catch (error) {
        console.log(error);
      }
    },
    async calcTotal() {
      let total = this.order.articles.reduce((sum, product) => {
        console.log(sum);
        console.log(product);
        console.log(product.total);
        return sum + Number(product.total);
      }, 0);
      this.order.total = total.toFixed(2);
      console.log(total);
    },
    async fetchOrder(order_id) {
      try {
        const payload = {
          id: order_id,
        };
        await this.$store.dispatch('fetchOrderById', payload);
        this.order = this.getOrderId.data[0];

        const jsonString = this.order.articles[0];
        this.order.articles = JSON.parse(jsonString);
        console.log('Articles ->', this.order.articles);
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'oops',
          text: error,
        });
      }
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(['getOrderId', 'getProductsSkuEan']),
  },
};
</script>

<style scoped>
.container {
  display: flex; /* Flexbox para organizar os filhos lado a lado */
  max-width: 1000px;
  margin: auto;
  font-family: Arial, sans-serif;
}

.main-content {
  flex: 2; /* A primeira parte ocupa mais espaço */
  padding-right: 20px;
}

.order-info {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
}

.article-list {
  list-style: none;
  padding: 0;
}

.article-list li {
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px 0;
  cursor: pointer;
}

.article-list li.selected {
  background: rgb(255, 147, 147, 0.4);
}

.sidebar {
  flex: 1; /* A sidebar ocupa menos espaço */
  background: #f8f8f8;
  padding: 15px;
  border-left: 1px solid #ddd;
  min-width: 250px; /* Garante um tamanho mínimo para a sidebar */
}

.sidebar h3 {
  margin-top: 0;
}

input {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
}

button {
  margin-top: 10px;
  padding: 8px 15px;
  background: rgb(255, 147, 147);
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background: rgb(255, 147, 147, 0.7);
}
</style>

<template>
  <div>
    <EditOrder :order="this.order" />
  </div>
</template>

<script>
import EditOrder from '@/components/EditOrder.vue';
export default {
  components: {
    EditOrder,
  },
};
</script>
